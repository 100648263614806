import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { GetWorkFlowCaseResponse } from "../api/lib/workflow/models/GetWorkflowCaseResponse"
import { getWorkFlowCase } from "../api/lib/workflow/workflow"

export const useGetWorkflowCase = () => {
  const { workflowId, workflowVersion, caseId } = useParams()
  const [workflowCase, setCase] = useState<GetWorkFlowCaseResponse | undefined>(
    undefined
  )

  useEffect(() => {
    if (workflowId && workflowVersion && caseId) {
      const fetchData = async () => {
        const response = await getWorkFlowCase({
          workflowId: workflowId,
          workflowVersion: workflowVersion,
          caseId: caseId,
        })
        setCase(response)
      }
      fetchData()
    }
  }, [workflowId, workflowVersion, caseId, setCase])

  return workflowCase
}
