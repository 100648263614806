import { useState } from "react"
import "./AdditionalAssistance.css"
import { TextAreaWithValidation } from "../../atoms/TextArea/TextAreaWithValidation"
import CheckBox from "../../atoms/CheckBox/CheckBox"

export interface AdditionalAssistanceProps {
  isRequiredAssistanceError: boolean
  isCharacterLimitError: boolean
  additionalAssistanceText: string
  setAdditionalAssistanceText: (value: string) => void
  onCheckboxChange?: (isChecked: boolean) => void
}
const AdditionalAssistance: React.FC<AdditionalAssistanceProps> = ({
  isRequiredAssistanceError,
  isCharacterLimitError,
  additionalAssistanceText,
  setAdditionalAssistanceText,
  onCheckboxChange,
}) => {
  const [isAssistanceBoxOpen, setIsAssistanceBoxOpen] = useState<boolean>(false)

  const handleSelectCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAssistanceBoxOpen(event.target.checked)
    if (onCheckboxChange) {
      onCheckboxChange(event.target.checked)
    }
  }

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAdditionalAssistanceText(e.target.value.trim())
  }

  return (
    <>
      <div className="additional-assistance-wrapper">
        <div className="additional-assistance-checkbox">
          <div>
            <CheckBox
              id="additional-assistance"
              isChecked={isAssistanceBoxOpen}
              onChange={(event) => {
                handleSelectCheckbox(event)
              }}
              shouldEnableFocusOutline
            />
          </div>
          <label
            htmlFor="additional-assistance"
            className="additional-assistance-label"
          >
            Do you require any additional assistance?
          </label>
        </div>
        {isAssistanceBoxOpen && (
          <TextAreaWithValidation
            className="additional-assistance-input"
            id="additional-assistance"
            name="additional-assistance"
            value={additionalAssistanceText}
            label="Special assistance details"
            topNote="Must be under 500 characters"
            placeholder="Type your specific requirement"
            onChange={handleTextAreaChange}
            displayError={isRequiredAssistanceError || isCharacterLimitError}
          />
        )}
      </div>
    </>
  )
}

export default AdditionalAssistance
