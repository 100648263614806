import { ReactElement } from "react"

import AccordianDown from "./AccordianDown"
import AccordianUp from "./AccordianUp"
import Activity from "./Activity"
import AddFolder from "./AddFolder"
import AppIcon from "./AppIcon"
import ArrowDown from "./ArrowDown"
import ArrowLeft from "./ArrowLeft"
import ArrowRight from "./ArrowRight"
import ArrowUp from "./ArrowUp"
import Attachment from "./Attachment"
import ButtonOptimisedDashboard from "./ButtonOptimisedDashboard"
import Calendar from "./Calendar"
import CheckboxActive from "./CheckboxActive"
import CheckboxDisabledActive from "./CheckboxDisabledActive"
import CheckboxDisabledInactive from "./CheckboxDisabledInactive"
import CheckboxInactive from "./CheckboxInactive"
import Clock from "./Clock"
import Connected from "./Connected"
import ConnectivityBannerLogos from "./ConnectivityBannerLogos"
import ConnectivityWarning from "./ConnectivityWarning"
import ContactAdded from "./ContactAdded"
import Copy from "./Copy"
import Cross from "./Cross"
import { DashboardHome } from "./DashboardHome"
import DragAndDrop from "./DragAndDrop"
import Elipsis from "./Elipsis"
import Error from "./Error"
import ErrorWarning from "./ErrorWarning"
import ExternalLink from "./ExternalLink"
import FileArrowUp from "./FileArrowUp"
import FileCSV from "./FileCSV"
import FileDoc from "./FileDoc"
import FileImport from "./FileImport"
import FilePDF from "./FilePDF"
import FileText from "./FileText"
import FileThumbnail from "./FileThumbnail"
import FileXLXS from "./FileXlXS"
import FilledError from "./FilledError"
import Filter from "./Filter"
import FnzBin from "./FnzBin"
import FnzDownload from "./FnzDownload"
import FnzNotification from "./FnzNotification"
import FnzUpload from "./FnzUpload"
import Folder from "./Folder"
import FolderIconsFinances from "./FolderIconsFinances"
import FolderIconsHomeProperty from "./FolderIconsHomeProperty"
import FolderIconsLegacyPlanning from "./FolderIconsLegacyPlanning"
import FolderIconsLegal from "./FolderIconsLegal"
import FolderIconsPersonalFamily from "./FolderIconsPersonalFamily"
import FolderIconsPropertyVehicles from "./FolderIconsPropertyVehicles"
import FolderIconsSavingsInvestments from "./FolderIconsSavingsInvestments"
import FolderIconsVehicles from "./FolderIconsVehicles"
import FolderImport from "./FolderImport"
import Folders from "./Folders"
import GreenInfo from "./GreenInfo"
import Guide from "./Guide"
import Hamburger from "./Hamburger"
import Hide from "./Hide"
import IconChevronDown from "./IconChevronDown"
import IconChevronLeft from "./IconChevronLeft"
import IconChevronLeftDouble from "./IconChevronLeftDouble"
import IconChevronRight from "./IconChevronRight"
import IconChevronRightDouble from "./IconChevronRightDouble"
import IconChevronUp from "./IconChevronUp"
import Info from "./Info"
import InfoDark from "./InfoDark"
import Link from "./LinkIcon"
import List from "./List"
import Logo from "./Logo"
import Logout from "./Logout"
import Minus from "./Minus"
import More from "./More"
import NomineeAdded from "./NomineeAdded"
import NotificationDelete from "./NotificationDelete"
import NotificationSuccess from "./NotificationSuccess"
import NotificationWarning from "./NotificationWarning"
import NotificationWarningGrey from "./NotificationWarningGrey"
import OutlineBell from "./OutlineBell"
import PdfNew from "./PdfNew"
import Pencil from "./Pencil"
import Pin from "./Pin"
import PinRemove from "./PinRemove"
import Plus from "./Plus"
import PointHand from "./PointHand"
import { PoweredByLegado } from "./PoweredByLegado"
import Progress from "./Progress"
import RadioActive from "./RadioActive"
import RadioInactive from "./RadioInactive"
import RedPlus from "./RedPlus"
import Search from "./Search"
import Secure from "./Secure"
import Share from "./Share"
import ShareUser from "./ShareUser"
import Sort from "./Sort"
import SquaresFour from "./SquaresFour"
import TheFamilyElephantWelcomeLogo from "./TFEWelcomeLogo"
import ThreeUsers from "./ThreeUsers"
import Tick from "./Tick"
import Tools from "./Tools"
import Union from "./Union"
import UploadDrag from "./UploadDrag"
import UploadFile from "./UploadFile"
import Verified from "./Verified"
import WelcomeConnect from "./WelcomeConnect"
import WelcomeLogo from "./WelcomeLogo"
import WelcomeOrganise from "./WelcomeOrganise"
import WelcomePortability from "./WelcomePortability"
import WelcomeSecure from "./WelcomeSecure"
import WelcomeShare from "./WelcomeShare"
import WelcomeStore from "./WelcomeStore"
import WhiteBell from "./WhiteBell"
import WhiteBellActive from "./WhiteBellActive"
import YellowAlert from "./YellowAlert"

export const GetIcon = {
  AccordianDown: <AccordianDown />,
  AccordianUp: <AccordianUp />,
  Activity: <Activity />,
  AddFolder: <AddFolder />,
  AppIcon: <AppIcon />,
  ArrowDown: <ArrowDown />,
  ArrowLeft: <ArrowLeft />,
  ArrowRight: <ArrowRight />,
  ArrowUp: <ArrowUp />,
  Attachment: <Attachment />,
  ButtonOptimisedDashboard: <ButtonOptimisedDashboard />,
  Calendar: <Calendar />,
  CheckboxActive: <CheckboxActive />,
  CheckboxDisabledActive: <CheckboxDisabledActive />,
  CheckboxDisabledInactive: <CheckboxDisabledInactive />,
  CheckboxInactive: <CheckboxInactive />,
  Clients: <ThreeUsers />,
  Clock: <Clock />,
  Connected: <Connected />,
  ConnectivityBannerLogos: <ConnectivityBannerLogos />,
  ConnectivityWarning: <ConnectivityWarning />,
  ContactAdded: <ContactAdded />,
  Copy: <Copy />,
  Cross: <Cross />,
  DashboardHome: <DashboardHome />,
  DragAndDrop: <DragAndDrop />,
  Elipsis: <Elipsis />,
  Error: <Error />,
  ErrorWarning: <ErrorWarning />,
  ExternalLink: <ExternalLink />,
  FileArrowUp: <FileArrowUp />,
  FileCSV: <FileCSV />,
  FileDoc: <FileDoc />,
  FileImport: <FileImport />,
  FilePDF: <FilePDF />,
  FileText: <FileText />,
  FileThumbnail: <FileThumbnail />,
  FileXLXS: <FileXLXS />,
  FilledError: <FilledError />,
  Filter: <Filter />,
  FnzBin: <FnzBin />,
  FnzDownload: <FnzDownload />,
  FnzNotification: <FnzNotification />,
  FnzUpload: <FnzUpload />,
  Folder: <Folder />,
  FolderIconsFinances: <FolderIconsFinances />,
  FolderIconsHomeProperty: <FolderIconsHomeProperty />,
  FolderIconsLegacyPlanning: <FolderIconsLegacyPlanning />,
  FolderIconsLegal: <FolderIconsLegal />,
  FolderIconsPersonalFamily: <FolderIconsPersonalFamily />,
  FolderIconsPropertyVehicles: <FolderIconsPropertyVehicles />,
  FolderIconsSavingsInvestments: <FolderIconsSavingsInvestments />,
  FolderIconsVehicles: <FolderIconsVehicles />,
  FolderImport: <FolderImport />,
  Folders: <Folders />,
  GreenInfo: <GreenInfo />,
  Guide: <Guide />,
  Hamburger: <Hamburger />,
  Hide: <Hide />,
  IconChevronDown: <IconChevronDown />,
  IconChevronLeft: <IconChevronLeft />,
  IconChevronLeftDouble: <IconChevronLeftDouble />,
  IconChevronRight: <IconChevronRight />,
  IconChevronRightDouble: <IconChevronRightDouble />,
  IconChevronUp: <IconChevronUp />,
  Info: <Info />,
  InfoDark: <InfoDark />,
  Link: <Link />,
  List: <List />,
  Logo: <Logo />,
  Logout: <Logout />,
  Minus: <Minus />,
  More: <More />,
  NomineeAdded: <NomineeAdded />,
  NotificationDelete: <NotificationDelete />,
  NotificationsIcon: <OutlineBell />,
  NotificationSuccess: <NotificationSuccess />,
  NotificationWarning: <NotificationWarning />,
  NotificationWarningGrey: <NotificationWarningGrey />,
  PdfNew: <PdfNew />,
  Pencil: <Pencil />,
  Pin: <Pin />,
  PinRemove: <PinRemove />,
  Plus: <Plus />,
  PointHand: <PointHand />,
  PoweredByLegado: <PoweredByLegado />,
  Progress: <Progress />,
  RadioActive: <RadioActive />,
  RadioInactive: <RadioInactive />,
  RedPlus: <RedPlus />,
  Search: <Search />,
  Secure: <Secure />,
  Share: <Share />,
  ShareUser: <ShareUser />,
  Sort: <Sort />,
  SquaresFour: <SquaresFour />,
  TheFamilyElephantWelcomeLogo: <TheFamilyElephantWelcomeLogo />,
  ThreeUsers: <ThreeUsers />,
  Tick: <Tick />,
  Tools: <Tools />,
  Union: <Union />,
  UploadDrag: <UploadDrag />,
  UploadFile: <UploadFile />,
  Verified: <Verified />,
  WelcomeConnect: <WelcomeConnect />,
  WelcomeLogo: <WelcomeLogo />,
  WelcomeOrganise: <WelcomeOrganise />,
  WelcomePortability: <WelcomePortability />,
  WelcomeSecure: <WelcomeSecure />,
  WelcomeShare: <WelcomeShare />,
  WelcomeStore: <WelcomeStore />,
  WhiteBell: <WhiteBell />,
  WhiteBellActive: <WhiteBellActive />,
  YellowAlert: <YellowAlert />,
} as { [key: string]: ReactElement }

export default GetIcon
