import { useState } from "react"
import { useKeyboard } from "../../../hooks/useKeyboard"
import { useRegisterOrResetPassword } from "./Utils/useRegisterOrResetPassword"

import { Button } from "../../atoms/Button"
import CheckBox from "../../atoms/CheckBox/CheckBox"
import AdditionalAssistance from "../AdditionalAssistance/AdditionalAssistance"
import { TextInputWithValidation } from "../../atoms/TextInput/TextInputWithValidation"
import AlertMessage, {
  MessageType,
} from "../../modules/AlertMessage/AlertMessage"
import { LoadingSpinner } from "../../atoms/LoadingSpinner/LoadingSpinner"
import { TermsAndConditionsText } from "./components/TermsAncConditionsText"

import { getSummaryErrors } from "../utils/getSummaryErrors"
import { submitButtonDetails } from "./Utils/utils"

import { PasswordSetupSteps } from "../../../contexts/application/constants"
import { PasswordRequirementsList } from "./components/PasswordRequirementsList"
import "./RegisterOrResetPassword.css"
import "react-phone-number-input/style.css"
import { InputFieldType } from "../../../utils/forms/validateInputs"

export enum RegisterOrResetPasswordErrorMessages {
  UNEXPECTED = "Something went wrong. Try again.",
  LOCKED = "For security reasons you have been locked out after too many failed attempts. Please try again in 30 minutes.",
}

type RegisterOrResetPasswordProps = {
  passwordSetupStep: PasswordSetupSteps
  activationCode: string
  email?: string
  onReset?: ({ password }: { password: string }) => void
  onRegister?: ({
    password,
    additionalAssistanceText,
  }: {
    password: string
    additionalAssistanceText: string
  }) => void
}
export const RegisterOrResetPassword = ({
  passwordSetupStep,
  activationCode,
  email,
  onRegister,
  onReset,
}: RegisterOrResetPasswordProps) => {
  const [password, setPassword] = useState<string>("")
  const [confirmPassword, setConfirmPassword] = useState<string>("")
  const [showPasswords, setShowPasswords] = useState(false)
  const [isAssistanceRequired, setIsAssistanceRequired] =
    useState<boolean>(false)
  const [additionalAssistanceText, setAdditionalAssistanceText] =
    useState<string>("")
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] =
    useState<boolean>(false)
  const [isTermsAndConditionsError, setIsTermsAndConditionsError] =
    useState<boolean>(false)

  const currentSubmitButtonDetails = submitButtonDetails[passwordSetupStep]

  const {
    isSubmitting,
    setIsSubmitting,
    isValidating,
    errorsPassword,
    errorConfirmPasswordMessage,
    isRequiredAssistanceError,
    isCharacterLimitError,
    hasUnexpectedError,
    hasUserLockedOutError,
  } = useRegisterOrResetPassword({
    password: password,
    confirmPassword: confirmPassword,
    isAssistanceRequired: isAssistanceRequired,
    additionalAssistanceText: additionalAssistanceText,
    isTermsAndConditionsChecked: isTermsAndConditionsChecked,
    activationCode: activationCode,
    passwordSetupStep: passwordSetupStep,
    onRegister: onRegister,
    onReset: onReset,
    setIsTermsAndConditionsError: setIsTermsAndConditionsError,
  })

  const handleSubmitClick = () => {
    setIsSubmitting(true)
  }

  useKeyboard("Enter", handleSubmitClick)

  return (
    <div>
      <div className="register-or-reset__body">
        <h1>
          {passwordSetupStep === PasswordSetupSteps.ResetPassword ? (
            <span>Reset your password</span>
          ) : (
            <>
              <span>Registration</span>
              <p>{email}</p>
            </>
          )}
        </h1>
        {hasUnexpectedError || hasUserLockedOutError ? (
          <div className="mb-30">
            <AlertMessage
              title="There's a problem"
              messageType={MessageType.ERROR}
              message={
                hasUnexpectedError
                  ? RegisterOrResetPasswordErrorMessages.UNEXPECTED
                  : RegisterOrResetPasswordErrorMessages.LOCKED
              }
            />
          </div>
        ) : null}
        {!hasUnexpectedError &&
        !hasUserLockedOutError &&
        (errorsPassword.length > 0 ||
          errorConfirmPasswordMessage !== undefined ||
          isRequiredAssistanceError ||
          isCharacterLimitError ||
          isTermsAndConditionsError) ? (
          <div className="mb-30">
            <AlertMessage
              title="There's a problem"
              messageType={MessageType.ERROR}
              message="Check the form. You must:"
              alertItems={getSummaryErrors({
                errorsPassword,
                errorConfirmPasswordMessage,
                isRequiredAssistanceError,
                isCharacterLimitError,
                isTermsAndConditionsError,
              })}
            />
          </div>
        ) : null}
        <div className="register-or-reset__enter-password">
          Enter the below to proceed
        </div>
        <div className="register-or-reset__create-password">
          <label htmlFor="password">
            <span className="required-asterisk">*</span>
            {passwordSetupStep === PasswordSetupSteps.SetupPassword
              ? "Create password"
              : "New Password"}
          </label>
          <div className="register-or-reset__create-password-requirements">
            <p className="register-or-reset__create-password-password-must">
              Your password must:
            </p>
            <PasswordRequirementsList
              isValidating={isValidating}
              errorsPassword={errorsPassword}
              passwordSetupStep={passwordSetupStep}
            />
            <TextInputWithValidation
              className="register-or-reset__create-password-input"
              id="password"
              name="password"
              type={
                showPasswords ? InputFieldType.TEXT : InputFieldType.PASSWORD
              }
              value={password}
              onChange={setPassword}
              displayError={
                errorsPassword.length > 0 ||
                errorConfirmPasswordMessage !== undefined
              }
              shouldShowWarningIcon={false}
              errorOnSubmit={{
                hasError:
                  errorsPassword.length > 0 ||
                  errorConfirmPasswordMessage !== undefined,
                message: errorConfirmPasswordMessage ?? "",
              }}
              validateOnSubmit
              hidePassword={!showPasswords}
              showPasswordToggleButton={false}
              hasAutoFocus
            />
          </div>
          <div className="register-or-reset__confirm-password">
            <label
              htmlFor="confirm-password"
              className="register-or-reset__confirm-password-label"
            >
              <span className="required-asterisk">*</span>
              Confirm password
            </label>
            <TextInputWithValidation
              className="register-or-reset__confirm-password-input"
              id="confirm-password"
              name="confirm-password"
              type={
                showPasswords ? InputFieldType.TEXT : InputFieldType.PASSWORD
              }
              value={confirmPassword}
              onChange={setConfirmPassword}
              displayError={errorConfirmPasswordMessage !== undefined}
              shouldShowWarningIcon={false}
              errorOnSubmit={{
                hasError:
                  errorConfirmPasswordMessage !== undefined ||
                  errorsPassword.length > 0,
                message: errorConfirmPasswordMessage ?? "",
              }}
              validateOnSubmit
              hidePassword={!showPasswords}
              showPasswordToggleButton={false}
            />
          </div>

          <div className="register-or-reset__checkboxes">
            <div className="register-or-reset__checkboxes-item">
              <div>
                <CheckBox
                  id="showPasswords"
                  isChecked={showPasswords}
                  onChange={() => setShowPasswords(!showPasswords)}
                  shouldEnableFocusOutline
                />
              </div>

              <label htmlFor="showPasswords">Show passwords</label>
            </div>
            {passwordSetupStep === PasswordSetupSteps.SetupPassword ? (
              <>
                <div className="register-or-reset__checkboxes-item">
                  <AdditionalAssistance
                    isRequiredAssistanceError={isRequiredAssistanceError}
                    isCharacterLimitError={isCharacterLimitError}
                    setAdditionalAssistanceText={setAdditionalAssistanceText}
                    additionalAssistanceText={additionalAssistanceText}
                    onCheckboxChange={(isChecked) =>
                      setIsAssistanceRequired(isChecked)
                    }
                  />
                </div>
                <hr />
                <div className="register-or-reset__checkboxes-item">
                  <div>
                    <CheckBox
                      id="termsAndConditions"
                      isChecked={isTermsAndConditionsChecked}
                      onChange={(event) => {
                        setIsTermsAndConditionsChecked(
                          !isTermsAndConditionsChecked
                        )
                        setIsTermsAndConditionsError(
                          event.currentTarget.checked ? false : true
                        )
                      }}
                      shouldEnableFocusOutline
                    />
                  </div>
                  <TermsAndConditionsText
                    isTermsAndConditionsError={isTermsAndConditionsError}
                    htmlForLabel="termsAndConditions"
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
        {/* TODO: Button component should support isBusy or isLoading prop and we should add the spinner there.  https://dev.azure.com/secure-the-file/Application/_workitems/edit/14437 */}
        <div className={currentSubmitButtonDetails.className}>
          <Button onClick={handleSubmitClick} isDisabled={isSubmitting}>
            {!isSubmitting && currentSubmitButtonDetails.text.default}
            {isSubmitting && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="mr-1">
                  {currentSubmitButtonDetails.text.submitting}
                </span>
                <LoadingSpinner
                  size="20px"
                  thickness="2px"
                  color="var(--color-universal-secondary-e)"
                />
              </div>
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}
