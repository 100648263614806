import React from "react"
import { IFile } from "../../../../../../api/api-client/api-types"
import { CaseDocument } from "../../../../../../api/lib/workflow/models/GetCaseDocumentResponse"
import { useThumbnails } from "../../../../../../hooks/useThumbnails"
import {
  Thumbnail,
  ThumbnailSize,
} from "../../../../../atoms/Thumbnail/Thumbnail"
import { UploadWizardSteps } from "../../../UploadFileToCaseModalWizard"
import "./ViewUploadedDocument.css"

export type ViewUploadedDocumentProps = {
  category: { stageId: string; requestId: string; name: string }
  document: CaseDocument
  file: IFile
  name: string
  requestIdFromCategory?: number
  setCurrentStep: (step: UploadWizardSteps) => void
  setFileSelectedForPreview: (file: IFile) => void
  setDocumentsToUpload: (documents: CaseDocument[]) => void
  setUnsavedChanges?: (unsavedChanges: boolean) => void
}

const DocumentCategory = ({
  category,
  document,
  requestIdFromCategory,
  setCurrentStep,
  setDocumentsToUpload,
  setUnsavedChanges,
}: {
  category: string
  document: CaseDocument
  requestIdFromCategory?: number
  setCurrentStep: (step: UploadWizardSteps) => void
  setDocumentsToUpload: (documents: CaseDocument[]) => void
  setUnsavedChanges?: (unsavedChanges: boolean) => void
}) => {
  return (
    <div className="uploaded-document">
      <div className="uploaded-document__document-category">
        <p>Document category:</p>
        <p className="uploaded-document__document-category-value">{category}</p>
        {/* only show change button if no request id was passed to upload modal */}
        {!requestIdFromCategory && (
          <button
            title="Change"
            onClick={() => {
              setUnsavedChanges?.(true)
              setDocumentsToUpload([document])
              setCurrentStep(UploadWizardSteps.AddDocumentDetails)
            }}
          >
            Change
          </button>
        )}
      </div>
    </div>
  )
}

export const ViewUploadedDocument: React.FC<ViewUploadedDocumentProps> = ({
  category,
  document,
  file,
  name,
  requestIdFromCategory,
  setCurrentStep,
  setDocumentsToUpload,
  setFileSelectedForPreview,
  setUnsavedChanges,
}) => {
  const isMobile = window.innerWidth < 744
  const { base64ThumbnailString } = useThumbnails({ file })
  return (
    <>
      <div className="uploaded-document">
        <div className="uploaded-document__details">
          <div className="uploaded-document__thumbnail">
            <Thumbnail
              fileExtension={file.extension}
              imageString={base64ThumbnailString}
              size={ThumbnailSize.MEDIUM1}
            />
          </div>
          <div className="uploaded-document__document-name">
            <p className="uploaded-document__document-name-label">
              Document name:
            </p>
            <p>{name}</p>
            <button
              title="Preview"
              onClick={() => setFileSelectedForPreview(file)}
            >
              Preview
            </button>
          </div>
        </div>
        {!isMobile && (
          <DocumentCategory
            category={category.name}
            document={document}
            requestIdFromCategory={requestIdFromCategory}
            setCurrentStep={setCurrentStep}
            setDocumentsToUpload={setDocumentsToUpload}
            setUnsavedChanges={setUnsavedChanges}
          />
        )}
      </div>
      {isMobile && (
        <div className="uploaded-document__category-container">
          <DocumentCategory
            category={category.name}
            document={document}
            requestIdFromCategory={requestIdFromCategory}
            setCurrentStep={setCurrentStep}
            setDocumentsToUpload={setDocumentsToUpload}
            setUnsavedChanges={setUnsavedChanges}
          />
        </div>
      )}
    </>
  )
}
